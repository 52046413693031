import React, { useState, useEffect } from 'react';

import { LoadingSpinner, NotAllowed, SelectItems } from "./_Common"
import { ItemsList } from './ItemsList';
import { NavItem } from './Nav';

import { convertUrlsToLinks, getWithExpiry, toArchiveText, whatsappStrToHtmlTags } from '../utils/utilityFunctions';
import { fetchData, getHomePageUrl, getPageHeader } from '../utils/apiServices';
import { useParams } from 'react-router-dom';


//InfoNav
const infoNavSortFunction = (a, b) => a.priority - b.priority;

function InfoNav({ homePageUrl }) {
    const token = getWithExpiry("token");

    const [navItems, setNavItems] = useState([]);

    useEffect(() => {
        (async () => {
            const additionalHeaders = token ? [{ name: 'token', value: token }] : [];

            const { data: fetchedData, error } = await fetchData('/api/InfoLinksItems', null, infoNavSortFunction, additionalHeaders);
            setNavItems(fetchedData);
        })();
    }, []);

    return (
        <div className="d-flex flex-wrap btn-group justify-content-center">
            {navItems.map((navItem, index) => (
                <NavItem navItem={navItem} homePageUrl={homePageUrl} isTextResponsive={false} key={index} />
            ))}
        </div>
    );
}


//infoItems
const infoItemsTransformFunction = (infoItem) => ({
    ...infoItem,
    textSearch: `${infoItem.text}`
});

function InfoItemToHtml(props) {
    const { homePageUrl, isRegList, ...item } = props;

    const displayText = item.isArchive ? toArchiveText(whatsappStrToHtmlTags(item.text)) : whatsappStrToHtmlTags(item.text);
    let displayMoreText = whatsappStrToHtmlTags(item.moreText).replace(/\r\n/g, '<br>');
    displayMoreText = convertUrlsToLinks(displayMoreText);
    const link = item.link ? item.link.replace("../../", homePageUrl) : "";
    
    if (item.displayType == "List" || isRegList)
        switch (item.type) {
            case 'WithText':
                return (
                    <div className="d-flex flex-wrap justify-content-between align-items-center w-100">
                        <dl className="mb-0">
                            <dt className="mb-1"> {displayText}</dt>
                            <dd className="ms-1 me-0" dangerouslySetInnerHTML={{ __html: displayMoreText }}></dd>
                            {item.link &&
                                <dd className="ms-1 me-0">
                                    <a href={link} target="_blank" rel="noopener noreferrer">
                                        {item.linkText}
                                    </a>
                                </dd>
                            }
                            {item.note && <dd className="ms-1 me-0"> {item.note} </dd>}
                        </dl>
                        {item.lastUpdateText &&
                            <span className="badge rounded-pill bg-secondary ml-auto align-self-end">
                                <small>{item.lastUpdateText}</small>
                            </span>
                        }
                    </div>
                );
            case 'OnlyLink':
                return (
                    <>
                        <div className="d-flex flex-wrap justify-content-between align-items-center w-100">
                            <a href={link} target="_blank" rel="noopener noreferrer">{displayText}</a>
                            {item.lastUpdateText &&
                                <span className="badge rounded-pill bg-secondary ml-auto align-self-end">
                                    <small>{item.lastUpdateText}</small>
                                </span>
                            }
                        </div>
                        {item.note && <div className="ms-0 me-2"> {item.note} </div>}
                    </>
                );
            default:
                return null;
        }
    else if (item.displayType == "Cards") {
        const isPdf = link && link.endsWith('.pdf');

        return (
            <div className="col">
                <div className="card h-100">
                    <div className="card-body text-center">
                        <a href={link} target="_blank" rel="noopener noreferrer">
                            {displayText}
                        </a>

                        {/* Show PDF preview if it's a PDF link */}
                        {isPdf && (
                            <div className="mt-3">
                                <iframe
                                    src={`https://docs.google.com/viewer?url=${encodeURIComponent(link)}&embedded=true&rm=demo`}
                                    width="100%"
                                    height="200px"
                                    style={{ border: 'none' }}
                                    title="PDF Preview"
                                />
                            </div>
                        )}
                    </div>

                    {item.lastUpdateText && (
                        <div className="card-footer text-muted">
                            <small>{item.lastUpdateText}</small>
                        </div>
                    )}
                    {item.note && <div className="mt-2">{item.note}</div>}
                </div>
            </div>
        );
    }
}


const toHtmlElements = (data, id, homePageUrl) => {
    const regList = id == null;
    return data.map((item, index) => (
        <InfoItemToHtml key={index} {...item} homePageUrl={homePageUrl} isRegList={regList} />
    ));
};

export default function InfoItems() {
    const { id } = useParams();
    const token = getWithExpiry("token");

    const [loading, setLoading] = useState(true);
    const [notAlowed, setNotAlowed] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);

    const [homePageUrl, setHomePageUrl] = useState(null);
    const [pageHeader, setPageHeader] = useState(null);
    const [grades, setGrades] = useState([]);
    const [selectedGrade, setSelectedGrade] = useState(-1);
    const [infoItemCategories, setInfoItemCategories] = useState([]);
    const [infoItems, setInfoItems] = useState([]);

    useEffect(() => {
        (async () => {
            const additionalHeaders = token ? [{ name: 'token', value: token }] : [];

            const { data: fetchedData, error } = await fetchData('/api/InfoItems', infoItemsTransformFunction, null, additionalHeaders);
            if (error && error === "Resource not found") setNotAlowed(true);
            else setInfoItems(fetchedData);

            const { data: fetchedDataIsAdmin, errorAdmin } = await fetchData('/api/IsAdminUser', null, null, additionalHeaders);
            if (!errorAdmin) setIsAdmin(fetchedDataIsAdmin);
            //InfoItemsAdmin

            const { data: fetchedDataCategories } = await fetchData('/api/InfoItemCategories', null, null, additionalHeaders);
            setInfoItemCategories(fetchedDataCategories);

            const { data: fetchedDataGrades } = await fetchData('/api/Grades');
            setGrades(fetchedDataGrades);

            const fetchedUrl = await getHomePageUrl();
            setHomePageUrl(fetchedUrl);

            const fetchedPageHeader = await getPageHeader({ pageName: "InfoItems" });
            setPageHeader(fetchedPageHeader);

            setLoading(false);
        })();
    }, []);

    if (notAlowed) { return <NotAllowed />; }
    if (loading) { return <LoadingSpinner />; }

    //Show InfoNav only on a not filtered page
    const msg =
        !id &&
        <>
            <div className="d-flex flex-wrap btn-group justify-content-end">
                <InfoNav homePageUrl={homePageUrl} />
            </div>
        </>
    const infoItemCategory = id && id > 0 && infoItemCategories && infoItemCategories.find(item => item.id == id);
    const isShowFilter = !id || infoItemCategory && infoItemCategory.isShowFilter;
    const displayType = infoItemCategory && infoItemCategory.displayType;
    const filterMore = grades && isShowFilter &&
        <SelectItems filterBy="grade" preText="הצגת מידע הנוגע לשכבה - " items={grades} defaultText="כל השכבות" selectedValue={selectedGrade} onSelect={(_, value) => setSelectedGrade(value)} isChoosableDefault="true" />;
    const infoItemCategoryName = infoItemCategories && infoItemCategories.length > 0 ? infoItemCategories.find(item => item.id == id)?.name : "";
    const adminLink = isAdmin ? <span className="ms-0 me-3"><a className="ms-0 me-5 btn btn-dark" href="/InfoItemsAdmin">ניהול פריטי מידע</a></span> : null;
    const header = token ? "אזור מורים" : pageHeader && (pageHeader + (id && infoItemCategoryName ? " - <span class='fw-bolder'>" + infoItemCategoryName + "</span>" : ""));
    //const filterCategories = infoItemCategories.filter(item => item.isShowOnInfoItemsPage === true);
    let curInfoItems = [...infoItems];
    if (id) curInfoItems = curInfoItems.filter(item => item.category == id);
    if (selectedGrade != -1) curInfoItems = curInfoItems.filter(item => item.grades === "" || item.grades.includes(selectedGrade));

    return (
        <>
            {adminLink}
            <ItemsList header={header} msg={msg} afterSearchBar={filterMore} items={curInfoItems} toHtml={(data) => toHtmlElements(data, id, homePageUrl)}
                filterCategories={!id ? infoItemCategories : null}
                noItemShow={id ? "true" : null}
                displayType={displayType}
            />
        </>
    )
};