import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from "react-router-dom";

import ReactGA from 'react-ga';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './css/StyleSheet.css';


import { fetchData, getInstParams } from './utils/apiServices';

import { LoadingSpinner, Logo, NotFound } from './components/_Common';
import Nav from "./components/Nav";
import IndexPageItems, { Row } from "./components/IndexPageItems";
import InfoItems from './components/InfoItems';
import ContactPage, { InstContactInfoFooter } from './components/Contacts';

import { fas } from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import { toPageTitle } from './utils/utilityFunctions';
import CoursePage from './components/Courses';
import { Calendar, Event } from './components/Calendars';
import Schedule from './components/Schedule';
import PicGallery, { PicGalleryForDir } from './components/PicGallery';
import Login from './components/Login';
import InfoItemsAdmin from './components/InfoItemsAdmin';
import Studies, { StudiesForFolder } from './components/Studies';
library.add(fas)


const DynamicManifest = () => {
  useEffect(() => {
    async function loadManifest() {
      try {
        const { data: manifest, error } = await fetchData('/api/Manifest');

        console.log(manifest);

        const stringManifest = JSON.stringify(manifest);
        const blob = new Blob([stringManifest], { type: "application/json" });
        const manifestObjectURL = URL.createObjectURL(blob);

        let manifestTag = document.getElementById("dynamic-manifest");
        if (!manifestTag) {
          manifestTag = document.createElement("link");
          manifestTag.setAttribute("rel", "manifest");
          manifestTag.setAttribute("id", "dynamic-manifest");
          document.head.appendChild(manifestTag);
        }

        manifestTag.setAttribute("href", manifestObjectURL);
      } catch (error) {
        console.error("Error loading manifest:", error);
      }
    }

    loadManifest();
  }, []);

  return null; // This component doesn't render anything visually
};


const useGoogleAnalytics = (trackingId) => {
  const location = useLocation();

  useEffect(() => {
    if (!trackingId) return;

    ReactGA.initialize(trackingId);
  }, [trackingId]);

  useEffect(() => {
    if (!trackingId) return;

    ReactGA.pageview(location.pathname + location.search);
  }, [location, trackingId]);
};


const useGoogleAnalytics_New = (trackingId, gtmId) => {
  const location = useLocation();

  // Initialize Google Analytics
  useEffect(() => {
    if (!trackingId) return;

    ReactGA.initialize(trackingId);
  }, [trackingId]);

  // Initialize Google Tag Manager (only on initial load)
  useEffect(() => {
    if (gtmId) {
      const tagManagerArgs = {
        gtmId: gtmId,
      };
      TagManager.initialize(tagManagerArgs);
    }
  }, [gtmId]);

  // Track page view changes
  useEffect(() => {
    if (trackingId) {
      ReactGA.pageview(location.pathname + location.search);
    }
  }, [location, trackingId]);
};

const InstFiles = ({ homePageUrl }) => {
  const [currentGA, setCurrentGA] = useState(null);

  useEffect(() => {
    async function loadInstFiles() {
      const { data: fetchedData, error } = await fetchData('/api/InstUtils');

      // Update the favicon
      const faviconLink = document.querySelector("link[rel*='icon']") || document.createElement("link");
      faviconLink.type = "image/x-icon";
      faviconLink.rel = "shortcut icon";
      faviconLink.href = `${homePageUrl}/${fetchedData.favicon}`;
      document.head.appendChild(faviconLink);

      // Dynamically load the CSS file
      const cssLink = document.createElement("link");
      cssLink.type = "text/css";
      cssLink.rel = "stylesheet";
      cssLink.href = `${homePageUrl}/${fetchedData.cssFile}`;
      document.head.appendChild(cssLink);

      const manifestLink = document.createElement("link");
      manifestLink.rel = "manifest";
      manifestLink.href = `${homePageUrl}/${fetchedData.manifest}`;
      document.head.appendChild(manifestLink);

      // Update meta description
      let metaDescription = document.querySelector("meta[name='description']");
      if (!metaDescription) {
        metaDescription = document.createElement("meta");
        metaDescription.setAttribute("name", "description");
        document.head.appendChild(metaDescription);
      }
      metaDescription.setAttribute("content", fetchedData.description);

      setCurrentGA(fetchedData.currentGA);
      //console.log("fetchedData.currentGA", fetchedData.currentGA);
    }

    loadInstFiles();
  }, [homePageUrl]);

  // Initialize Google Analytics with the ID from fetchedData
  useGoogleAnalytics(currentGA);
  //console.log("currentGA", currentGA);
  useGoogleAnalytics_New(currentGA, "G-LQYTK281Z2");//GTM-WTDZQMP8

  return null; // this component doesn't render anything visually
};

function NonHomePageHeader() {
  const [isShowNav, setIsShowNav] = useState(null);
  const [navLocation, setNavLocation] = useState(null);

  useEffect(() => {
    (async () => {
      const fetchedNavType = await getInstParams();
      setIsShowNav(fetchedNavType.isShowNavOtherPages);
      setNavLocation(fetchedNavType.navLocationOtherPages.toLowerCase());
    })();
  }, []);

  return <>
    {isShowNav && navLocation === "top" && <div><Nav /></div>}
    <div className="d-flex justify-content-center pt-1"><Logo /></div>
    {isShowNav && navLocation === "middle" && <div className="py-3 w-md-75 mx-auto"><Nav /></div>}
  </>;
}

function NonHomePageFooter() {
  const location = useLocation();

  if (location.pathname !== "/") {
    return <><InstContactInfoFooter /></>;
  }

  return null;
}
function Home({ instDescription }) {
  const [indexHeaderTextItems, setIndexHeaderTextItems] = useState([]);
  const [navLocation, setNavLocation] = useState(null);

  useEffect(() => {
    (async () => {
      const { data: fetchedData, error } = await fetchData('/api/IndexHeaderTextItems');
      setIndexHeaderTextItems(fetchedData);

      const fetchedNavType = await getInstParams();
      setNavLocation(fetchedNavType.navLocationIndex.toLowerCase());
    })();
  }, []);

  document.title = toPageTitle(instDescription);
  return (
    indexHeaderTextItems &&
    <>
      {navLocation === "top" && <div className=""><Nav /></div>}
      <div className="container">
        <div className="hideOnLargeScreen">
          <div className="d-flex justify-content-between pt-1">
            {indexHeaderTextItems.map((item, index) => (
              <span key={index}>{item.shortText}</span>
            ))}
          </div>
        </div>
        <div className="d-flex justify-content-center pt-1">
          <Logo />
        </div>
        <div className="hideOnSmallScreen">
          <div className="d-flex justify-content-between pt-1">
            {indexHeaderTextItems.map((item, index) => (
              <span key={index} className="fw-bolder h6">{item.text}</span>
            ))}
          </div>
        </div>
        {navLocation === "middle" && <div className="mt-3"><Nav /></div>}
      </div>
      <Routes>
        <Route path="/" element={<IndexPageItems />} />
      </Routes>
    </>
  );
}

export default function App() {
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  const [instDetails, setInstDetails] = useState(null);
  useEffect(() => {
    (async () => {
      const { data: fetchedData, error } = await fetchData('/api/InstDetails');
      setInstDetails(fetchedData);

      setLoading(false);
    })();
  }, []);


  if (instDetails) document.title = toPageTitle(instDetails.description);
  if (loading) { return <LoadingSpinner />; }
  return (
    <div className="container-fluid rounded mx-auto">
      {instDetails &&
        <>
          <InstFiles homePageUrl={instDetails.homePageUrl} />
          {/* <DynamicManifest /> */}

          {location.pathname !== "/" && <NonHomePageHeader />}

          <Routes>
            <Route path="/" element={<Home instDescription={instDetails.description} />} />

            <Route path="/Row/:id" element={<Row />} />

            <Route path="/Courses" element={<CoursePage />} />
            <Route path="/Courses/:id" element={<CoursePage />} />
            <Route path="/Calendars" element={<Calendar />} />
            <Route path="/Events" element={<Event />} />
            <Route path="/InfoItems" element={<InfoItems />} />
            <Route path="/InfoItems/:id" element={<InfoItems />} />
            <Route path="/Contacts" element={<ContactPage instDetails={instDetails} />} />
            <Route path="/Schedule" element={<Schedule />} />
            <Route path="/Schedule/All" element={<Schedule />} />

            <Route path="/PicGallery" element={<PicGallery />} />
            <Route path="/PicGallery/:name" element={<PicGalleryForDir />} />

            <Route path="Studies" element={<Studies />} />
            <Route path="Studies/:name" element={<StudiesForFolder />} />

            <Route path="/Login" element={<Login />} />

            <Route path="/InfoItemsAdmin" element={<InfoItemsAdmin />} />

            <Route path="*" element={<NotFound homePageUrl={instDetails.homePageUrl} />} />
          </Routes>

          <InstContactInfoFooter instDetails={instDetails} />
        </>
      }
    </div>
  );
}